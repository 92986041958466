$fa-font-path: "../font";
$html-font-size: 16;
$white: #fff;
$black: #000;
$grey: #E6E7E7;
$red: #F27471;
$yellow: #F8F057;

@font-face {
  font-family: 'Starlit';
  src: url('../font/StarlitDrive.woff2') format('woff2'),
  url('../font/StarlitDrive.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$primary-font: "azo-sans-web", sans-serif;
$starlit-font: Starlit, serif;
$secondary-font: Helvetica Neue, Helvetica, Arial, sans-serif;
