@import "variables";
@import "framework/framework";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $black;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	// @include desktop-sm-down {
	// 	font-size: 15px;
	// }

	// @include tablet-down {
	// 	font-size: 14px;
	// }

	// @include phone-down {
	// 	font-size: 13px;
	// }

	// @include phone-sm-down {
	// 	font-size: 12px;
	// }
}

.popup {
	background-color: $red;
	padding: rems(50) rems(80);
	max-width: rems(400);
	color: $white;
	margin: 0 auto;
	// background-image: linear-gradient(270deg, #FB4CF8 0%, #FC95FA 52%, #A682F9 100%);
	border-radius: 19px;
	position: relative;

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: rems(30);

		p {
			font-size: rems(18);
			font-weight: bold;
			text-align: center;

			span {}
		}

		.inputs-wrap {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}

		label {
			display: block;
		}

		input {
			border: none;
			margin-bottom: rems(35);
		}

		select {
			width: 100%;
			margin-bottom: rems(35);
		}

		option {
			width: 100%;
		}

		button {
			border: 1px solid $white;
			color: $white;
			background-color: transparent;
			text-transform: uppercase;
			padding: rems(5) rems(25);

			@include hover-focus {
				color: $yellow;
				background-color: $white;
			}
		}
	}

}

body {
	background-color: $red;
	background-image: url(../img/bg/Layer-1-reverse.png);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: $white;
	width: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
	min-height: 100vh;
	position: relative;
}

a {
	color: inherit;

	@include hover-focus {
		color: $white;
	}
}

#main {
	padding: rems(81) rems(145) 0 rems(50);
	// min-height: 100vh;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: rems(120);

	@media screen and (max-width: 1100px) {
		flex-direction: column;
		padding: 0;
	}

	@media screen and (max-width: 700px) {
		padding-bottom: 0;
		gap: rems(30);
	}

	// h1 {
	// 	align-self: flex-start;
	// 	padding: 0 rems(50);

	// 	@media screen and (max-width: 700px) {
	// 		align-self: center;
	// 		margin-bottom: rems(25);
	// 	}

	// 	img {
	// 		max-width: rems(277);

	// 		@media screen and (max-width: 700px) {
	// 			max-width: rems(150);
	// 		}
	// 	}
	// }

	.hero-wrapper {
		flex: 1;
		text-align: center;
		padding-bottom: rems(120);

		@media screen and (max-width: 1100px) {
			padding: 0;
			width: 100%;
			padding-top: rems(30);
		}

		.hero {
			width: 100%;
			max-width: rems(1100);
			margin-bottom: rems(10);


			@media screen and (max-width: 1100px) {
				max-width: rems(700);
				margin: rems(10) auto;
			}

			@media screen and (max-width: 700px) {
				max-width: rems(340);
			}
		}

		.sub-title {
			font-size: rems(31);
			color: $grey;
			font-family: $primary-font;
			text-transform: uppercase;

			@media screen and (max-width: 700px) {
				font-size: rems(24);
			}
		}

		.presave-wrapper {
			padding: rems(30) 0 0;
			margin: 0 auto;
			font-weight: bold;
			z-index: 10;
			max-width: rems(400);
			width: 100%;

			@media screen and (max-width: 700px) {
				flex-direction: column;
				gap: rems(8);
				padding: rems(24) 0 rems(7);
				max-width: rems(300);
				align-items: center;
			}

			// .btn-wrapper {
			// 	display: flex;
			// 	justify-content: center;


			// 	@media screen and (min-width: 701px) {
			// 		gap: rems(18);
			// 	}

			// 	@media screen and (max-width: 700) {
			// 		display: flex;
			// 		flex-direction: column;
			// 		align-items: center;
			// 	}

			// 	.listen {
			// 		@media screen and (max-width: 700px) {
			// 			min-width: rems(180);
			// 			padding: rems(12) rems(11);
			// 			align-self: center;

			// 		}
			// 	}

			// 	#signup {
			// 		max-width: rems(150);
			// 		align-self: center;
			// 	}
			// }

			a {
				flex: 1;
				display: inline-block;
				font-size: rems(14);
				// background-color: $yellow;
				background: transparent linear-gradient(270deg, #FB4CF8 0%, #FC95FA 52%, #A682F9 100%) 0% 0% no-repeat padding-box;
				// color: $red;
				color: $white;
				border-radius: 19px;
				padding: rems(12) 0;
				text-align: center;
				text-transform: uppercase;
				transition: 0.3s all ease-in-out;

				@media screen and (max-width: 700px) {
					margin: rems(3.5) 0;
				}
			}

			.large-btn {
				display: block;
				width: 100%;
				margin-bottom: rems(14);

				@include hover-focus {
					transform: scale(1.1);
				}

				i {
					padding-right: rems(16);
					margin-left: rems(-10);

					&.listen {
						@media screen and (max-width: 700px) {
							padding-right: 0;
							margin-left: 0;
						}
					}
				}
			}

			// #play-pause {
			// 	color: $white;
			// 	border: none;
			// 	display: inline-block;
			// 	font-size: rems(14);
			// 	font-weight: bold;
			// 	background-color: #FB4CF8;
			// 	background-image: linear-gradient(270deg, #FB4CF8 0%, #FC95FA 52%, #A682F9 100%);
			// 	background-repeat: no-repeat;
			// 	background-position: 0% 0%;
			// 	background-size: padding-box;
			// 	border-radius: 19px;
			// 	padding: rems(12) 0;
			// 	width: rems(134);
			// 	text-align: center;
			// 	transition: 0.3s all ease-in-out;

			// 	@include hover-focus {
			// 		transform: scale(1.1);
			// 	}


			// 	i {
			// 		padding-right: rems(20);
			// 		margin-left: rems(-12);
			// 	}
			// }

			.btn-wrapper {
				display: flex;
				margin-top: rems(22);

				@media screen and (max-width: 700px) {
					flex-direction: column;
					margin-top: 0;
				}

				a {
					// background-image: linear-gradient(270deg, #FB4CF8 0%, #FC95FA 52%, #A682F9 100%);
					// background-repeat: no-repeat;
					// background-position: 0% 0%;
					// background-size: padding-box;
					width: rems(134);
					text-align: center;
					transition: 0.3s all ease-in-out;
					text-transform: uppercase;
					font-weight: bold;

					@media screen and (max-width: 700px) {
						width: 100%;
					}

					@include hover-focus {
						transform: scale(1.1);
					}


					i {
						padding-right: rems(16);
						margin-left: rems(-8);

						@media screen and (max-width: 700px) {
							margin-left: 0;
						}
					}

					span {
						padding-right: rems(16);
						margin-left: rems(-20);
					}
				}
			}
		}
	}

	.embed-wrapper {
		flex: 1;
		width: 100%;

		@media screen and (max-width: 1100px) {
			padding: 0 rems(40);
		}

		@media screen and (max-width: 700px) {
			padding: 0 rems(12);
		}

		.embed-container {
			width: 100%;

			iframe {}
		}
	}


	// .legal {
	// 	max-width: rems(448);
	// 	font-size: rems(9);
	// 	font-family: $secondary-font;
	// 	text-align: center;
	// 	line-height: 1.3em;
	// 	z-index: 10;
	// 	margin-bottom: rems(100);
	// 	pointer-events: none;

	// 	@media screen and (max-width: 700px) {
	// 		max-width: 50vw;
	// 		margin-bottom: rems(20);
	// 	}
	// }

	// .troll-container {
	// 	position: absolute;
	// 	left: 0;
	// 	right: 0;
	// 	bottom: 0;

	// 	.troll {
	// 		transition: 0.5s ease-in-out;
	// 		width: rems(200);
	// 		height: 50vh;
	// 		position: absolute;

	// 		@media screen and (max-width: 700px) {
	// 			width: rems(80);
	// 			height: 30vh;
	// 		}

	// 		img {
	// 			position: absolute;
	// 			bottom: 0;
	// 			left: 50%;
	// 			transform: translateX(-50%);
	// 			pointer-events: none;
	// 		}
	// 	}

	// 	.troll:hover {
	// 		transform: translateY(rems(-120));

	// 		@media screen and (max-width: 700px) {
	// 			transform: translateY(rems(-60));
	// 		}
	// 	}

	// 	.first {
	// 		left: 8%;
	// 		z-index: 3;
	// 		bottom: rems(-120);

	// 		@media screen and (max-width: 700px) {
	// 			left: 0;
	// 			bottom: rems(-60);
	// 		}

	// 		img {
	// 			max-width: rems(375);

	// 			@media screen and (max-width: 700px) {
	// 				max-width: rems(160);
	// 			}
	// 		}
	// 	}

	// 	.second {
	// 		left: 26%;
	// 		z-index: 5;
	// 		bottom: rems(-120);

	// 		@media screen and (max-width: 700px) {
	// 			left: 15%;
	// 			bottom: rems(-60);
	// 		}

	// 		img {
	// 			max-width: rems(375);

	// 			@media screen and (max-width: 700px) {
	// 				max-width: rems(170);
	// 			}
	// 		}
	// 	}

	// 	.third {
	// 		left: 45%;
	// 		z-index: 3;
	// 		bottom: rems(-120);

	// 		@media screen and (max-width: 700px) {
	// 			left: 40%;
	// 			bottom: rems(-60);
	// 		}

	// 		img {
	// 			max-width: rems(375);

	// 			@media screen and (max-width: 700px) {
	// 				max-width: rems(180);
	// 			}
	// 		}
	// 	}

	// 	.fourth {
	// 		left: 63%;
	// 		z-index: 5;
	// 		bottom: rems(-100);

	// 		@media screen and (max-width: 700px) {
	// 			left: 60%;
	// 			bottom: rems(-40);
	// 		}

	// 		img {
	// 			max-width: rems(400);

	// 			@media screen and (max-width: 700px) {
	// 				max-width: rems(200);
	// 			}
	// 		}
	// 	}

	// 	.fourth:hover {
	// 		transform: translateY(rems(-100));

	// 		@media screen and (max-width: 700px) {
	// 			transform: translateY(rems(-40));
	// 		}
	// 	}

	// 	.fifth {
	// 		left: 80%;
	// 		z-index: 3;
	// 		bottom: rems(-100);

	// 		@media screen and (max-width: 700px) {
	// 			left: 82%;
	// 			bottom: rems(-25);
	// 		}

	// 		img {
	// 			max-width: rems(380);

	// 			@media screen and (max-width: 700px) {
	// 				max-width: rems(160);
	// 			}
	// 		}
	// 	}

	// 	.fifth:hover {
	// 		transform: translateY(rems(-100));

	// 		@media screen and (max-width: 700px) {
	// 			transform: translateY(rems(-25));
	// 		}
	// 	}
	// }
}

footer {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	display: flex;

	@media screen and (max-width: 1100px) {
		flex-direction: column-reverse;
		align-items: center;
		text-align: center;
		position: static;
		padding-top: rems(20);
	}

	.copy-wrapper {
		margin: 0;
		padding: 0 rems(50) rems(8);
		width: 100%;

		@media screen and (max-width: 700px) {
			padding: 0 rems(5) 0;
		}

		.copyright {
			// font-weight: 100;
			font-family: $secondary-font;


			@media screen and (max-width: 700px) {
				text-align: center;
			}

			.rca {
				max-width: rems(65);
				padding-bottom: rems(10);
				// pointer-events: none;

				@media screen and (max-width: 700px) {
					margin: 0 auto;
				}
			}

			p {
				font-size: rems(8);
				width: 100%;

				a {
					// pointer-events: auto;
				}
			}
		}
	}

	ul.social {
		display: flex;
		align-items: center;
		list-style: none;
		gap: rems(12);
		padding-right: rems(40);

		li {
			a {
				display: block;
				max-width: rems(20);
				transition: all .3s ease-in-out;
	
				@include hover-focus {
					transform: scale(1.2);
				}

				@media screen and (max-width: 1100px) {
				}

				img {
				}
			}
		}
	}
}